import { createApi } from '@reduxjs/toolkit/query/react';
import { transformErrorResponse, transformSucessResponse } from '../../utils/API/responseMiddleware';
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest, encryptQueryString } from '../../utils/API/requestMiddleware';

const localAPI = createApi({
    reducerPath: 'localAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        // Get Local List
        getLocals: builder.mutation({
            query: (details) => ({
                url: '/Local/GetLocals',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Add Local
        addLocal: builder.mutation({
            query: (details) => ({
                url: '/Local/AddLocal',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update Local
        updateLocal: builder.mutation({
            query: (details) => ({
                url: '/Local/UpdateLocal',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Delete Local
        deleteLocal: builder.mutation({
            query: (localID) => ({
                url: encryptQueryString(`/Local/DeleteLocal/?id=${localID}`),
                method: 'DELETE',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllLocals: builder.query({
            query: () => ({
                url: '/Local/GetAllLocals',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    })
});

export const { useGetLocalsMutation, useAddLocalMutation, useUpdateLocalMutation, useDeleteLocalMutation, useLazyGetAllLocalsQuery } = localAPI

export default localAPI;