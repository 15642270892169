import { createApi } from '@reduxjs/toolkit/query/react';
import { transformErrorResponse, transformSucessResponse } from '../../utils/API/responseMiddleware';
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest, encryptQueryString } from '../../utils/API/requestMiddleware';


const craftAPI = createApi({
    reducerPath: 'craftAPI',
    baseQuery: customFetchBase, // Replace with your API endpoint
    endpoints: (builder) => ({
        addCraft: builder.mutation({
            query: (craftsdetails) => ({
                url: '/Crafts/AddCrafts', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(craftsdetails)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        updateCraft: builder.mutation({
            query: (craftDetails) => ({
                url: `/Crafts/UpdateCraft`,
                method: 'Post',
                body: transformRequest(craftDetails),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteCraft: builder.mutation({
            query: (craftId) => ({
                url: encryptQueryString(`/Crafts/DeleteCraft/?id=${craftId}`),
                method: 'DELETE',
            }),

            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getCraft: builder.mutation({
            query: (craftQuery) => ({
                url: '/Crafts/GetCrafts', // Replace with your authentication endpoint
                method: 'POST',
                body: transformRequest(craftQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllCraft: builder.query({
            query: () => ({
                url: '/Crafts/GetAllCraft',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    }),

});

export const { useAddCraftMutation, useUpdateCraftMutation, useDeleteCraftMutation, useGetCraftMutation, useLazyGetAllCraftQuery } = craftAPI;
export default craftAPI;
