import React from 'react';
import './App.css';
import AppRoutes from './layouts/navigation/AppRoutes';
import { ComponentNavigation } from './layouts/navigation/ComponentNavigation';

function App() {
  return (
    <AppRoutes  componentRoutes={ComponentNavigation}/>
  );
}

export default App;
