import { AppIcons } from "../../../data/appIcons";
import { securityKey } from "../../../data/SecurityKey";

export const Menu = [
  // {
  //   name: "Employers Search",
  //   id: "EmployersSearch",
  //   to: "/EmployersSearch",
  //   image: AppIcons.employerSearchMenu,
  //   children: [],
  //   securityKey: securityKey.EMPLOYERSSEARCH
  // },
  {
    name: "Employers",
    id: "Employers",
    to: "/Employer",
    image: AppIcons.employerMenu,

    children: [],
    securityKey: securityKey.EMPLOYERPAGE
  },

  {
    name: "Contracts",
    id: "Contracts",
    to: "/Contracts",
    image: AppIcons.contaractMenu,
    children: [],
    securityKey: securityKey.CONTRACTS
  },
  {
    name: "Associations",
    id: "Associations",
    to: "/Associations",
    image: AppIcons.associationsMenu,
    children: [],
    securityKey: securityKey.ASSOCIATIONS
  },
  {
    name: "Reports",
    id: "reports",
    to: "",
    image: AppIcons.reportMenu,
    securityKey: securityKey.REPORTS,
    children: [
      {
        name: "Employer List",
        id: "Employer List",
        to: "/EmployerList",
        image: "",
        securityKey: securityKey.EMPLOYERLIST
      },
      {
        name: "Contract by Craft Report",
        id: "Contract by Craft Report",
        to: "/ContractByCraft",
        image: "",
        securityKey: securityKey.CONTRACTBYCRAFTREPORT
      },
      {
        name: "Employer Demographic Report",
        id: "Employer Demographic Report",
        to: "/EmployerDemographic",
        image: "",
        securityKey: securityKey.EMPLOYERDEMOGRAPHICREPORT
      },
      {
        name: "Contracts by Association Report",
        id: "Contract by Craft Report",
        to: "/ContractsByAssociation",
        image: "",
        securityKey: securityKey.CONTRACTSBYASSOCIATIONREPORT
      },
      {
        name: "Unsigned Employers",
        id: "Unsigned Employers",
        to: "/UnsignedEmployers",
        image: "",
        securityKey: securityKey.UNSIGNEDEMPLOYERS
      },
      {
        name: "Bond Report",
        id: "Bond Report",
        to: "/BondReport",
        image: "",
        securityKey: securityKey.BONDREPORT
      },
      {
        name: "Contractor by Sign Date",
        id: "Contractor by Sign Date",
        to: "/ContractorBySignDate",
        image: "",
        securityKey: securityKey.CONTRACTORBYSIGNDATE
      },
    ],
  },

  {
    name: "Security",
    id: "security",
    to: "",
    image: AppIcons.securityMenu,
    securityKey: securityKey.SECURITY,
    children: [
      {
        name: "Permissions",
        id: "permissions",
        to: "/Permissions",
        image: "",
        securityKey: securityKey.PERMISSIONS
      },
      {
        name: "User Management",
        id: "userManagement",
        to: "/Users",
        image: "",
        securityKey: securityKey.USERMANAGEMENT
      },
      {
        name: "Security Group Management",
        id: "securityGroupManagement",
        to: "/SecurityGroupManagement",
        image: "",
        securityKey: securityKey.SECURITYGROUPMANAGEMENT
      },
    ],
  },
  {
    name: "Utilities",
    id: "utilities",
    to: "",
    image: AppIcons.utilityMenu,
    securityKey: securityKey.UTILITIES,
    children: [
      {
        name: "Crafts",
        id: "Crafts",
        to: "/Crafts",
        image: "",
        securityKey: securityKey.CRAFTS
      },

      {
        name: "Locals",
        id: "locals",
        to: "/Locals",
        image: "",
        securityKey: securityKey.LOCALS
      },
      {
        name: "Contractor Work Types",
        id: "Contractor Work Types",
        to: "/ContractorWorkTypes",
        image: "",
        securityKey: securityKey.CONTRACTORWORKTYPES
      },
      {
        name: "Employer Types",
        id: "Employer Types",
        to: "/EmployerTypes",
        image: "",
        securityKey: securityKey.EMPLOYERTYPES
      },
      {
        name: "Contract Types",
        id: "Contract Types",
        to: "/ContractTypes",
        image: "",
        securityKey: securityKey.CONTRACTTYPES
      },
      {
        name: "Employer Merge",
        id: "employerMerge",
        to: "/EmployerMerge",
        image: "",
        securityKey: securityKey.EMPLOYERMERGE
      },
    ],
  },
  // {
  //     name: "Manage User",
  //     id: "userManage",
  //     to: "",
  //     image: AppIcons.userManage,
  //     
  //     children: [
  //         {
  //             name: "Add / Edit User",
  //             id: "AddEditUser",
  //             to: "/AddEditUser",
  //             image: "",
  //             
  //         },
  //         {
  //             name: "View All User",
  //             id: "ViewAllUser",
  //             to: "/ViewAllUser",
  //             image: "",
  //             
  //         }
  //     ]
  // },
];
