import Image from "../../components/image/Image";
import { AppIcons } from "../../data/appIcons";
import ResetPasswordComponent from "../../features/resetPassword/Component/ResetPassword";
import "./ResetPassword.scss";

const ResetPassword = () => {
  return (
    <div className="reset-section">
      <div class="container">
        <div class="col-md-12 col-12">
          <div class="login-header-section">
            <Image imagePath={AppIcons.logoImage} altText="button Icon" />
          </div>
        </div>
      </div>
      <ResetPasswordComponent />
    </div>
  );
};

export default ResetPassword;
