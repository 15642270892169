import { createApi } from '@reduxjs/toolkit/query/react';
import { transformErrorResponse, transformSucessResponse } from '../../utils/API/responseMiddleware';
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest, encryptQueryString } from '../../utils/API/requestMiddleware';

const employerTypesAPI = createApi({
    reducerPath: 'employerAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        // Add CompanyTypes
        addCompanyType: builder.mutation({
            query: (details) => ({
                url: '/CompanyType/AddCompanyType',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Update CompanyTypes
        updateCompanyType: builder.mutation({
            query: (details) => ({
                url: '/CompanyType/UpdateCompanyType',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Delete CompanyTypes
        deleteCompanyType: builder.mutation({
            query: (companyID) => ({
                url: encryptQueryString(`/CompanyType/DeleteCompanyType/?id=${companyID}`),
                method: 'DELETE'
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Get CompanyTypes
        getCompanyTypes: builder.mutation({
            query: (details) => ({
                url: '/CompanyType/GetCompanyTypes',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    })
})

export const {
    useGetCompanyTypesMutation,
    useAddCompanyTypeMutation,
    useUpdateCompanyTypeMutation,
    useDeleteCompanyTypeMutation } = employerTypesAPI;

export default employerTypesAPI;