import React, { useEffect, useRef, useState } from "react";
import FormCreator from "../../../components/Forms/FormCreator";
import ResetPasswordData from "../ResetPassword.data";
import { useResetPasswordMutation } from "../../../app/services/userAPI";
import ToastService from "../../../services/toastService/ToastService";
import { useNavigate } from "react-router-dom";
import { getAuthProps } from "../../../lib/authenticationLibrary";
import Buttons from "../../../components/ui/button/Buttons";
import { AppIcons } from "../../../data/appIcons";
import Image from "../../../components/image/Image";

const ResetPassword = () => {
    const resetPasswordRef = useRef();
    const authData = getAuthProps();
    const [resetForm, setResetForm] = useState(ResetPasswordData);

    const naviagate = useNavigate();
    const [resetPassword, { isLoading, isSuccess, data: resetPasswordData }] = useResetPasswordMutation();

    useEffect(() => {
        if (authData) {
            const newFrom = { ...resetForm }
            newFrom.initialState.userName = authData.user?.userName ? authData.user?.userName : "";
            setResetForm(newFrom);
        }
    }, []);

  useEffect(() => {
    if (isSuccess && resetPasswordData) {
      if (resetPasswordData.errorMessage) {
        ToastService.success(resetPasswordData.errorMessage);
        naviagate("/Employer");
      }
    }
  }, [isSuccess, resetPasswordData]);


    const handleClick = () => {
        let resetPasswordData = resetPasswordRef.current.getFormData(); // this will help to get form data.
        if (resetPasswordData) {
            // If it's not an edit 
            const reqParams = {
                userID: authData.user?.userID ? authData.user?.userID : 0,
                password: resetPasswordData.newPassword,
                passwordResetRequired: true
            }
            resetPassword(reqParams);
        }
    };

    return (
      <React.Fragment>
        <div className="bottom-dark-section">
          <div className="container">
            <div className="password-reset-section">
              <div className="row">
                <div className="col-xl-6 col-12">
                  <div className="form-section">
                  <Image
                      imagePath={AppIcons.clientLogo}
                      altText="button Icon"
                    />
                    <h1>Reset Password Page </h1>
                    <form className="row">
                      <FormCreator
                        ref={resetPasswordRef}
                        {...resetForm}
                      ></FormCreator>
                    </form>
                    <div className="button-part mt-4">
                      <Buttons
                        buttonTypeClassName="success-btn"
                        buttonText="Reset Password"
                        onClick={handleClick}
                        isLoading={isLoading}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-12 d-flex align-items-center">
                  <div className="img-section">
                    <Image
                      imagePath={AppIcons.resetPasswordImg}
                      altText="button Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
};

export default ResetPassword;
