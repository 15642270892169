import React from "react";
const Label = React.lazy(() => import('../../label/Label'));
const Input = React.lazy(() => import('../input/Input'));
const ValidationText = React.lazy(() => import('../validation/ValidationText'));

const FormInputFields = ({
  labelName,
  name,
  onChange,
  onValidation,
  dataField,
  error,
  formSetting,
  ...inputProps
}) => {
  const handleInputChange = (e) => {
    if (onChange) {
      onChange(dataField, e.target.value);
    }
  };

  const handleOnBlur = () => {
    if (onValidation) {
      onValidation(dataField);
    }
  };

  return (
    <>
      <div className="input-label-part">
        {labelName && labelName !== "" && <Label labelName={labelName} for={name} />}
        <Input {...inputProps} onChange={handleInputChange} onBlur={handleOnBlur} isDisable={formSetting?.isViewOnly || inputProps?.isDisable || false } />
      </div>
      <ValidationText error={error || ""} />
    </>
  );
};

export default FormInputFields;
