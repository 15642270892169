import React from "react";
import "./PageLoader.scss";
import { AppIcons } from "../../../data/appIcons";
import Image from "../../image/Image";

function PageLoader() {
  return (
    <div className="page-loader-background">
      <div className="loader-section">
        <Image
          imgId="one"
          imagePath={AppIcons.loaderIcon1}
          imgCustomClassName="st2"
          altText="light-img"
        />
        <Image
          imgId="two"
          imagePath={AppIcons.loaderIcon2}
          imgCustomClassName="st2"
          altText="light-img"
        />
        <Image
          imgId="three"
          imagePath={AppIcons.loaderIcon3}
          imgCustomClassName="st2"
          altText="light-img"
        />
        <Image imagePath={AppIcons.loaderIcon8} altText="main-img" />
        <Image
          imgId="four"
          imagePath={AppIcons.loaderIcon4}
          imgCustomClassName="st2"
          altText="light-img"
        />
        <Image
          imgId="five"
          imagePath={AppIcons.loaderIcon5}
          imgCustomClassName="st2"
          altText="light-img"
        />
        <Image
          imgId="six"
          imagePath={AppIcons.loaderIcon6}
          imgCustomClassName="st2"
          altText="light-img"
        />
        <Image
          imgId="seven"
          imagePath={AppIcons.loaderIcon7}
          imgCustomClassName="st2"
          altText="light-img"
        />
      </div>
    </div>
  );
}

export default PageLoader;
