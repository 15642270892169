import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest, encryptQueryString } from '../../utils/API/requestMiddleware';
import { transformErrorResponse, transformSucessResponse } from '../../utils/API/responseMiddleware';


const contractTypeAPI = createApi({
    reducerPath: 'contractTypeAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        addContractType: builder.mutation({
            query: (details) => ({
                url: '/ContractType/AddContractType',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        updateContractType: builder.mutation({
            query: (details) => ({
                url: '/ContractType/UpdateContractType',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getContractType: builder.mutation({
            query: (details) => ({
                url: '/ContractType/GetContractType',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteContractType: builder.mutation({
            query: (details) => ({
                url: encryptQueryString(`/ContractType/DeleteContractType/?id= ${details}`),
                method: 'DELETE',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

    }),
});

export const { useAddContractTypeMutation, useUpdateContractTypeMutation, useGetContractTypeMutation, useDeleteContractTypeMutation } = contractTypeAPI;

export default contractTypeAPI;