export const securityKey = {
    EMPLOYERSSEARCH: "EmployersSearch",

    EMPLOYERPAGE: "EmployerPage",
    ADDEMPLOYER: "AddEmployer",
    EDITEMPLOYER: "EditEmployer",
    DELETEEMPLOYER: "DeleteEmployer",

    CONTRACTS: "Contracts",
    ADDCONTRACTS: "AddContracts",
    EDITCONTRACTS: "EditContracts",
    DELETECONTRACTS: "DeleteContracts",

    ASSOCIATIONS: "Associations",
    ADDASSOCIATIONS:"AddAssociations",
    EDITASSOCIATIONS:"EditAssociations",
    DELETEASSOCIATIONS:"DeleteAssociations",

    REPORTS: "Reports",
    EMPLOYERLIST: "EmployerList",
    CONTRACTBYCRAFTREPORT: "ContractByCraftReport",
    EMPLOYERDEMOGRAPHICREPORT: "EmployerDemographicReport",
    CONTRACTSBYASSOCIATIONREPORT: "ContractsByAssociationReport",
    UNSIGNEDEMPLOYERS: "UnsignedEmployers",
    BONDREPORT: "BondReport",
    CONTRACTORBYSIGNDATE: "ContractorBySignDate",
    SECURITY: "Security",

    PERMISSIONS: "Permissions",
    ADDPERMISSIONS: "AddPermissions",

    //TODO : KP Need to check for the below keys.
    USERMANAGEMENT: "UserManagement",
    ADDUSER: "AddUser",
    EDITUSER: "EditUser",
    DELETEUSER: "DeleteUser",

    SECURITYGROUPMANAGEMENT: "SecurityGroupManagement",
    ADDSECURITYGROUP: "AddGroup",
    EDITSECURITYGROUP: "EditGroup",
    DELETESECURITYGROUP: "DeleteGroup",

    UTILITIES: "Utilities",

    CRAFTS: "Crafts",
    ADDCRAFTS: "AddCrafts",
    EDITCRAFTS: "EditCrafts",
    DELETECRAFTS: "DeleteCrafts",

    LOCALS: "Locals",
    ADDLOCALS: "AddLocals",
    EDITLOCALS: "EditLocals",
    DELETELOCALS: "DeleteLocals",

    CONTRACTORWORKTYPES: "ContractorWorkTypes",
    ADDCONTRACTORWORKTYPES: "AddContractorWorkTypes",
    EDITCONTRACTORWORKTYPES: "EditContractorWorkTypes",
    DELETECONTRACTORWORKTYPES: "DeleteContractorWorkTypes",

    EMPLOYERTYPES: "EmployerTypes",
    ADDEMPLOYERTYPES: "AddEmployerTypes",
    EDITEMPLOYERTYPES: "EditEmployerTypes",
    DELETEEMPLOYERTYPES: "DeleteEmployerTypes",

    CONTRACTTYPES: "ContractTypes",
    ADDCONTRACTTYPES: "AddContractTypes",
    EDITCONTRACTTYPES: "EditContractTypes",
    DELETECONTRACTTYPES: "DeleteContractTypes",

    EMPLOYERMERGE: "EmployerMerge",
};