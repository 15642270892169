import Image from "../../../components/image/Image";
import { AppIcons } from "../../../data/appIcons";
import { hasPermission } from "../../../utils/AuthorizeNavigation/authorizeNavigation";

const SideBarMenuItem = (props) => {
  return (
    <>
      {hasPermission(props.securityKey) ?
        <div className="dropdown-list">
          <span>
            <Image imagePath={props.image} altText="Menu Icon" />
            {props.name}
          </span>
          {props.childLength > 0 ? (
            <span className="down-arrow-icon">
              <Image imagePath={AppIcons.arrowIcon} altText="Menu Arrow" />
            </span>
          ) : null}
        </div>
        :
        null
      }
    </>
  );
};

export default SideBarMenuItem;
