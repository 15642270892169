import React from "react";
import { AppIcons } from "../../../data/appIcons";
import "./Header.scss";
import { getAuthProps } from "../../../lib/authenticationLibrary";
import Image from "../../../components/image/Image";
import { Link } from "react-router-dom";

const Header = () => {
  const cookie = getAuthProps();
  return (
    <div className="header-section">
      <div className="logo-profile-section">
        <div className="logo-part">
          <Link to={"/EmployersSearch"}>
            <Image imagePath={AppIcons.logoImageW} altText="Logo" />
          </Link>
        </div>
        <div className="profile-part">
          <span className="user-name">
            {cookie && cookie != null
              ? cookie.user.userName
                ? cookie.user.userName
                : ""
              : ""}
          </span>
          <div className="profile-img">
            <Image
              imagePath={AppIcons.userImage}
              imgCustomClassName="open-bar"
              altText="User Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
