import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase} from '../../utils/API/fetchBaseQuery';


const groupAPI = createApi({
    reducerPath: 'groupAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        // Get Groups List
        getGroups: builder.mutation({
            query: (groupQuery) => ({
                url: '/Group/GetGroups',
                method: 'POST',
                body: transformRequest(groupQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllGroups: builder.query({
            query: () => ({
                url: '/Group/GetAllGroups',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Add Group
        addGroup: builder.mutation({
            query: (groupDetails) => ({
                url: '/Group/AddGroup',
                method: 'POST',
                body: transformRequest(groupDetails)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Update Group
        updateGroup: builder.mutation({
            query: (groupDetails) => ({
                url: '/Group/UpdateGroup',
                method: 'POST',
                body: transformRequest(groupDetails)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Delete Group
        deleteGroup: builder.mutation({
            query: (groupId) => ({
                url: encryptQueryString(`/Group/DeleteGroup/?id=${groupId}`),
                method: 'DELETE',
                body: transformRequest(groupId)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getGroupDetailsByGroupId: builder.query({
            query: (groupId) => ({
                url: encryptQueryString(`/Group/GetGroupDetailsByGroupId/?id=${Number(groupId)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        })
    })
})


export const { useGetGroupsMutation,
    useAddGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useLazyGetGroupDetailsByGroupIdQuery,
    useLazyGetAllGroupsQuery
} = groupAPI;

export default groupAPI;