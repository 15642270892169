export const FormFieldTypes = {
    INPUT: 1,
    PASSWORD: 2,
    NUMERIC: 3,
    CHECKBOX: 4,
    DATEPICKER: 5,
    SELECT: 6,
    TEXTAREA: 7,
    // DECIMAL :4
    // NUMERIC: 4,
    // PASSWORD: 7,
    // CURRENCY: 8
}
