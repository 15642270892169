import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';

const associationAPI = createApi({
    reducerPath: 'associationAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        getAssociations: builder.mutation({
            query: (details) => ({
                url: '/Association/GetAssociations',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addAssociation: builder.mutation({
            query: (details) => ({
                url: '/Association/AddAssociation',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        updateAssociation: builder.mutation({
            query: (details) => ({
                url: '/Association/UpdateAssociation',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteAssociation: builder.mutation({
            query: (details) => ({
                url: encryptQueryString(`/Association/DeleteAssociation/?id=${Number(details)}`),
                method: 'DELETE',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAssociationById: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/Association/GetAssociationDetailByAssociationID/?associationID=${Number(details)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        })

    }),

});

export const { useGetAssociationsMutation, useAddAssociationMutation, useUpdateAssociationMutation, useDeleteAssociationMutation, useLazyGetAssociationByIdQuery } = associationAPI;

export default associationAPI