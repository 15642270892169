import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';


const employeeAPI = createApi({
    reducerPath: 'employeeAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        getEmployees: builder.mutation({
            query: (details) => ({
                url: '/Employer/GetEmployers',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addEmployee: builder.mutation({
            query: (details) => ({
                url: '/Employer/AddEmployer',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        updateEmployee: builder.mutation({
            query: (details) => ({
                url: '/Employer/UpdateEmployer',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteEmployee: builder.mutation({
            query: (details) => ({
                url: encryptQueryString(`/Employer/DeleteEmployer/?id=${details}`),
                method: 'DELETE',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllEmployeeTypes: builder.query({
            query: () => ({
                url: '/Employer/GetAllEmployerTypes',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getEmployeeByID: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/Employer/GetEmployerDetailsByEmployerID/?id=${details}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const { useGetEmployeesMutation, useDeleteEmployeeMutation, useAddEmployeeMutation, useUpdateEmployeeMutation, useLazyGetAllEmployeeTypesQuery, useLazyGetEmployeeByIDQuery } = employeeAPI;

export default employeeAPI;