import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';


const bondAPI = createApi({
    reducerPath: 'bondAPI',
    baseQuery: customFetchBase,

    endpoints: (builder) => ({

        getCompanyBonds: builder.mutation({
            query: (details) => ({
                url: '/CompanyBond/GetCompanyBonds',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addCompanyBond: builder.mutation({
            query: (details) => ({
                url: '/CompanyBond/AddCompanyBond',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteCompanyBond: builder.mutation({
            query: (details) => ({
                url: encryptQueryString(`/CompanyBond/DeleteCompanyBond/?id=${details}`),
                method: 'DELETE',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        })
    }),
});

export const { useGetCompanyBondsMutation, useAddCompanyBondMutation, useDeleteCompanyBondMutation } = bondAPI;

export default bondAPI