import React from "react";
import { FormFieldTypes } from "../../data/formFieldType";
// Control
import FormInputFields from "../ui/inputs/formField/FormInputFields";

// Constant 
import { TextInputType } from "../../data/formControlTypes";
import FormTextAreaFields from "../ui/inputs/formField/FormTextAreaField";

const FormSelectField = React.lazy(() => import('../ui/inputs/formField/FormSelectField'));
const FormCheckboxField = React.lazy(() => import('../ui/inputs/formField/FormCheckBoxFields'));
const FormDatePickerField = React.lazy(() => import('../ui/inputs/formField/FormDatePickerField'));




const FormFields = ({
	fields,
	formData,
	validState,
	onFormStateChange,
	onUpdateValidation,
	formSetting
}) => {
	const handleInputChange = (dataField, value) => {
		const updatedData = { ...formData, [dataField]: value };
		onFormStateChange && onFormStateChange(updatedData);
	};



	const renderField = (field, index) => {
		const { containerCss } = field.style || { containerCss: "col-md-6" }

		switch (field.fieldType) {
			case FormFieldTypes.INPUT:
			case FormFieldTypes.PASSWORD:
			case FormFieldTypes.NUMERIC:
				return (
					<div className={containerCss}>
						<FormInputFields
							key={field.dataField}
							dataField={field.dataField}
							labelName={field.lable}
							name={field.id}
							type={fieldTypeToInputType(field.fieldType)}
							value={formData?.[field.dataField] || ""}
							onChange={handleInputChange}
							error={validState.error[field.dataField] || ""}
							onValidation={onUpdateValidation}
							formSetting={formSetting}
							{...field.fieldSetting}
						/>
					</div>
				);

			case FormFieldTypes.TEXTAREA:
				return (
					<div className={containerCss}>
						<FormTextAreaFields
							key={field.dataField}
							dataField={field.dataField}
							labelName={field.lable}
							name={field.id}
							type={fieldTypeToInputType(field.fieldType)}
							value={formData?.[field.dataField] || ""}
							onChange={handleInputChange}
							error={validState.error[field.dataField] || ""}
							onValidation={onUpdateValidation}
							formSetting={formSetting}
							{...field.fieldSetting}
						/>
					</div>
				)
			case FormFieldTypes.CHECKBOX:
				// Render a checkbox input
				return (
					<div className={containerCss} key={field.dataField}>
						<FormCheckboxField
							labelName={field.lable}
							dataField={field.dataField}
							type={fieldTypeToInputType(field.fieldType)}
							checked={formData?.[field.dataField] || false}
							name={field.id}
							onChange={handleInputChange}
							formSetting={formSetting}
							{...field.fieldSetting}
						/>
					</div>
				);
			case FormFieldTypes.DATEPICKER:
				// Render a checkbox input
				return (
					<div className={containerCss} key={field.dataField}>
						<FormDatePickerField
							labelName={field.lable}
							dataField={field.dataField}
							selectedDate={formData?.[field.dataField] || null} //TODO : Check if datefield value is in proper date format
							name={field.id}
							onValidation={onUpdateValidation}
							onChange={handleInputChange}
							error={validState.error[field.dataField] || ""}
							formSetting={formSetting}
							{...field.fieldSetting}
						/>
					</div>
				);
			case FormFieldTypes.SELECT:
				return (
					<div className={containerCss} key={field.dataField}>
						<FormSelectField
							labelName={field.lable}
							dataField={field.dataField}
							selectedOption={field.value}
							options={field.options}
							name={field.id}
							onValidation={onUpdateValidation}
							value={formData?.[field.dataField] || null}
							error={validState.error[field.dataField] || ""}
							onChange={handleInputChange}
							formSetting={formSetting}
							{...field.fieldSetting}
						/>
					</div>
				)
			// Add similar cases for other field types (FileUpload, Radio, Date, DateTime)
			default:
				return null;
		}
	};



	return <>{


		fields.map(renderField)



	}</>;
}

const fieldTypeToInputType = (fieldtype) => {

	switch (fieldtype) {
		case FormFieldTypes.PASSWORD:
			return TextInputType.PASSWORD;
		case FormFieldTypes.NUMERIC:
			return TextInputType.NUMBER;
		case FormFieldTypes.CHECKBOX:
			return TextInputType.CHECKBOX;
		case FormFieldTypes.DATEPICKER:
			return TextInputType.DATEPICKER;
		case FormFieldTypes.TEXTAREA:
			return TextInputType.TEXT;
		default:
			return TextInputType.TEXT;
	}
};
export default FormFields;
