import React, { useState } from "react";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import { AppIcons } from "../data/appIcons";
import { Outlet, useLocation } from "react-router-dom";
import BredcomTitle from "./components/bredcomTitle/BredcomTitle";
import Image from "../components/image/Image";
import { hasPermission } from "../utils/AuthorizeNavigation/authorizeNavigation";
import Unauthorize from "../pages/unauthorize/Unauthorize";

const Layout = (props) => {
  // Get the current location using React Router's useLocation hook
  const location = useLocation();

  // Extract the last segment of the pathname as routhPath
  let routhPath = location.pathname.replace("/", "");
  const segments = location.pathname.split('/');
  if (segments.length > 1) {
    routhPath = segments[1];
  }

  // Find the matching component route based on the routhPath
  const renderComponent = routhPath
    ? props.componentRoutes.find((x) => x.id.toLowerCase() === routhPath.toLowerCase())
    : "";

  // Check if the user is authorized to access the current route
  const isAuthorize = hasPermission(renderComponent.securityKey);

  // State to control the opening and closing of the sidebar
  const [openCloseSidebar, setOpenCloseSidebar] = useState(false);

  // Function to toggle the sidebar's open/close state
  const toggleSidebar = () => {
    setOpenCloseSidebar(!openCloseSidebar);
  };

  return (
    <React.Fragment>
      <div className="main-page-layout">
        <Header />
        <div
          className={`middle-page-section${openCloseSidebar ? " collapse-sidebar" : ""
            }`}
        >
          <div className="sidebar-collapse-btn" onClick={toggleSidebar}>
            <Image
              imagePath={AppIcons.barsIcon}
              imgCustomClassName="open-bar"
              altText="Menu Icon"
            />
          </div>
          <Sidebar componentRoutes={props.componentRoutes} />

          {isAuthorize ?
            <div className="center-content-part">
              <div className="content-desc-section">
                <BredcomTitle
                  pageTitle={renderComponent ? renderComponent.title : ""}
                />
                <div className="center-container">
                  <Outlet />
                </div>
              </div>
            </div>
            : <Unauthorize />
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
