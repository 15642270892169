import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';

const contractsAPI = createApi({
    reducerPath: 'contractsAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        getContracts: builder.mutation({
            query: (details) => ({
                url: '/Contract/GetContracts',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        addContract: builder.mutation({
            query: (details) => ({
                url: '/Contract/AddContract',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        updateContract: builder.mutation({
            query: (details) => ({
                url: '/Contract/UpdateContract',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteContract: builder.mutation({
            query: (details) => ({
                url: encryptQueryString(`/Contract/DeleteContract/?id=${details}`),
                method: 'DELETE',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getContractByContractID: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/Contract/GetContractDetailByContractID/?id=${Number(details)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllUnAssignedContractCrafts: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/Contract/GetAllUnAssignedContractCrafts/?ContractID=${Number(details)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        assignContractCraftToCraft: builder.mutation({
            query: (details) => ({
                url: '/Contract/AssignContractCraftToCraft',
                method: 'POST',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAssignedCraft: builder.mutation({
            query: (details) => ({
                url: '/Contract/GetAssignedCraftDetailsByContractID',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        deleteAssignedCrafts: builder.mutation({
            query: (details) => ({
                url: `/Contract/DeleteAssignedContarctCraft?/CraftID=${details.craftID}&ContractID=${details.contractID}`,
                method: 'DELETE',
                body: transformRequest(details),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllContracts: builder.query({
            query: () => ({
                url: '/Contract/GetAllContract',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

    }),
});

export const { useGetContractsMutation,
    useAddContractMutation,
    useUpdateContractMutation,
    useDeleteContractMutation,
    useLazyGetContractByContractIDQuery,
    useLazyGetAllUnAssignedContractCraftsQuery,
    useAssignContractCraftToCraftMutation,
    useGetAssignedCraftMutation,
    useDeleteAssignedCraftsMutation,
    useLazyGetAllContractsQuery
} = contractsAPI;

export default contractsAPI;
