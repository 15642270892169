import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from '../../utils/API/fetchBaseQuery';

const userAPI = createApi({
    reducerPath: 'userAPI',
    baseQuery: customFetchBase,
    tagTypes: ['User'],
    endpoints: (builder) => ({

        // Get User List
        getUsers: builder.mutation({
            query: (userQuery) => ({

                url: '/User/GetUsers',
                method: 'POST',
                body: transformRequest(userQuery)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAssignUsersByGroupID: builder.query({
            query: (groupID) => ({
                // url: encryptQueryString(`/User/GetAssignUsersByGroupID/?groupID=${Number(groupID)}`),
                url: `/User/GetAssignUsersByGroupID/?groupID=${groupID}`,
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Add User 
        addUser: builder.mutation({
            query: (userDetails) => ({
                url: '/User/AddUser',
                method: 'POST',
                body: transformRequest(userDetails)
            }),
            // invalidatesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Update User
        updateUser: builder.mutation({
            query: (userDetails) => ({
                url: '/User/UpdateUser',
                method: 'POST',
                body: transformRequest(userDetails)
            }),
            invalidatesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Get User Details by Id
        getUserById: builder.query({
            query: (userID) => ({
                url: encryptQueryString(`/User/GetUserDetailByUserID/?id=${Number(userID)}`),
                method: 'GET',
            }),
            providesTags: ['User'],
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Delete User
        deleteUser: builder.mutation({
            query: (userID) => ({
                url: `/User/DeleteUser/?id=${userID}`,
                method: 'DELETE',
                body: transformRequest(userID)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Change Password
        resetPassword: builder.mutation({
            query: (userID) => ({
                url: `/User/ResetPassword/`,
                method: 'POST',
                body: transformRequest(userID)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getUnAssignedGroupsByUserId: builder.query({
            query: (userID) => ({
                url: encryptQueryString(`/UserGroup/GetUnAssignedGroupDetailsByUserID/?id=${Number(userID)}`),
                Method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        assignUserToGroup: builder.mutation({
            query: (userDetails) => ({
                url: `/UserGroup/AssignUserToGroup`,
                method: 'POST',
                body: transformRequest(userDetails)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteAssignedUserGroup: builder.mutation({
            query: ({ userID, groupID }) => ({
                url: encryptQueryString(`/UserGroup/DeleteAssignedUserGroup/?groupId=${groupID}&userId=${userID}`),
                method: 'DELETE',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllPagesByGroupId: builder.query({
            query: ({ groupID, securityKeyParentID }) => ({
                url: encryptQueryString(`/UserGroup/GetAllPagesByGroupId/?groupID=${groupID}&SecurityKeyParentID=${securityKeyParentID}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAllSecuritySettings: builder.query({
            query: () => ({
                url: '/UserGroup/GetAllSecuritySettings',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        getAssignedGroupDetails: builder.mutation({
            query: (details) => ({
                url: `/UserGroup/GetAssignedGroupDetailsByUserID/`,
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Get Users By GroupID
        getUsersByGroupId: builder.mutation({
            query: (groupDetails) => ({
                url: `/UserGroup/GetUsersByGroupId`,
                method: 'POST',
                body: transformRequest(groupDetails)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        deleteUserGroup: builder.mutation({
            query: (userGroupID) => ({
                url: `/UserGroup/DeleteUserGroup/?userGroupID=${userGroupID}`,
                method: 'DELETE',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        })

    })
})

export const { useGetUsersMutation,
    useAddUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useLazyGetUserByIdQuery,
    useResetPasswordMutation,
    useAssignUserToGroupMutation,
    useGetAssignedGroupDetailsMutation,
    useLazyGetUnAssignedGroupsByUserIdQuery,
    useDeleteAssignedUserGroupMutation,
    useLazyGetAllSecuritySettingsQuery,
    useGetUsersByGroupIdMutation,
    useLazyGetAssignUsersByGroupIDQuery,
    useDeleteUserGroupMutation,
    useLazyGetAllPagesByGroupIdQuery
} = userAPI;

export default userAPI;