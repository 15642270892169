import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformSucessResponse, transformErrorResponse } from "../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../utils/API/requestMiddleware"
import { customFetchBase } from "../../utils/API/fetchBaseQuery";

const securityAPI = createApi({
    reducerPath: 'securityAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        // Get Security Permission By Group ID
        getSecurityPermissionByGroupID: builder.query({
            query: (groupID) => ({
                url: encryptQueryString(`/Security/GetSecurityPermissionByGroupID/?GroupID=${groupID}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        // Add Security Permissions
        addSecurityPermissions: builder.mutation({
            query: (details) => ({
                url: '/Security/AddSecurityPermissions',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse
        }),
    })
})

export const { useLazyGetSecurityPermissionByGroupIDQuery, useAddSecurityPermissionsMutation } = securityAPI;

export default securityAPI;