import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.scss";
import { Menu } from "../menu/Menu";
import { AppIcons } from "../../../data/appIcons";
import SideBarMenuItem from "./SideBarMenuItem";
import SideBarSubMenuItem from "./SideBarSubMenuItem";
import { logout } from "../../../app/slice/authSlice";
import { useDispatch } from "react-redux";
import Image from "../../../components/image/Image";
import { hasPermission } from "../../../utils/AuthorizeNavigation/authorizeNavigation";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const [clickedValue, setClickedValue] = useState(null);
  const [clickedValueSubMenu, setClickedValueSubMenu] = useState(null);


  const handleItemClick = (item) => {
    if (clickedValue === item) {
      setClickedValue(null);
    } else {
      setClickedValue(item);
    }
  };

  const handleSubItemClick = (e, item) => {
    setClickedValueSubMenu(item);
    e.stopPropagation();
  };

  return (
    <div className={`sidebar-section`}>
      <div className="menu-list-items">
        <ul className="menu-scrolling">
          {Menu.map((item, Index) => (
            <>
              {/* {grantdeny(item.name)} */}

              {item.children.length > 0 ?

                (
                  <li
                    key={Index}
                    className={`${clickedValue === item ? "active" : ""}`}
                    onClick={() => handleItemClick(item)}
                  >
                    <SideBarMenuItem
                      image={item.image}
                      name={item.name}
                      childLength={item.children.length}
                      securityKey={item.securityKey}
                    />
                    <SideBarSubMenuItem
                      children={item.children}
                      handleSubItemClick={handleSubItemClick.bind(this)}
                      clickedValueSubMenu={clickedValueSubMenu}
                    />
                  </li>
                )

                :
                hasPermission(item.securityKey) ?
                  (
                    <>
                      <li
                        key={Index}
                        className={`${clickedValue === item ? "active" : ""}`}
                        onClick={() => handleItemClick(item)}
                      >
                        <Link to={item.to}>
                          <span>
                            <Image imagePath={item.image} altText="Menu Icon" />
                            {item.name}
                          </span>
                        </Link>
                      </li>
                    </>
                  )
                  : null
              }
            </>
          ))}
        </ul>
        <div className="logout-btn-sec">
          <ul>
            <li>
              <button>
                <Image imagePath={AppIcons.logoutMenu} altText="Logout Icon" />
                <span
                  className="logout-text"
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Logout
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
