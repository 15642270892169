import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const SwalServices = withReactContent(Swal);

const SwalAlert = () => {

    const success = (text, title) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'success',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const error = (text, title) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const warning = (title, text) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    };

    const info = (title, text) => {
        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        });
    }

    const confirm = (title, text, saveBtnText, cancelBtnText) => {

        return SwalServices.fire({
            title: title,
            text: text,
            icon: 'question',
            iconHtml: '<span className="warning-icons"><img src="/warningtick.png"/></span>',
            showCancelButton: true,
            confirmButtonText: saveBtnText,
            cancelButtonText: cancelBtnText,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
        }).then((result) => {
            if (result.value) {
                return true;
            } else if (result.dismiss === SwalServices.DismissReason.cancel) {
                return false;
            }
        });
    };


    // Export the SwalService functions
    return { confirm, success, warning, info, error }

};


export default SwalAlert;