import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react';

import authReducer from './slice/authSlice';
import authapi from './services/authAPI';
import userAPI from './services/userAPI';
import groupAPI from './services/groupAPI';
import craftAPI from './services/craftAPI';
import securityAPI from './services/securityAPI';
import employerTypesAPI from './services/employerTypesAPI';
import workTypeAPI from './services/workTypeAPI';
import contractTypeAPI from './services/contractTypeAPI';
import contractsAPI from './services/ContractAPI'
import employeeAPI from './services/employeeAPI';
import localAPI from './services/localAPI';
import associationAPI from './services/associationAPI';
import bondAPI from './services/bondAPI';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authapi.reducerPath]: authapi.reducer,
    [craftAPI.reducerPath]: craftAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [groupAPI.reducerPath]: groupAPI.reducer,
    [securityAPI.reducerPath]: securityAPI.reducer,
    [employerTypesAPI.reducerPath]: employerTypesAPI.reducer,
    [workTypeAPI.reducerPath]: workTypeAPI.reducer,
    [contractTypeAPI.reducerPath]: contractTypeAPI.reducer,
    [contractsAPI.reducerPath]: contractsAPI.reducer,
    [employeeAPI.reducerPath]: employeeAPI.reducer,
    [localAPI.reducerPath]: localAPI.reducer,
    [associationAPI.reducerPath]: associationAPI.reducer,
    [bondAPI.reducerPath]: bondAPI.reducer
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    authapi.middleware,
    craftAPI.middleware,
    userAPI.middleware,
    groupAPI.middleware,
    securityAPI.middleware,
    employerTypesAPI.middleware,
    workTypeAPI.middleware,
    contractTypeAPI.middleware,
    contractsAPI.middleware,
    employeeAPI.middleware,
    localAPI.middleware,
    associationAPI.middleware,
    bondAPI.middleware,
  ),
})
setupListeners(store.dispatch);
