// Loader Image
import loader1 from "../assets/images/loader-img/lamp-1.png";
import loader2 from "../assets/images/loader-img/lamp-2.png";
import loader3 from "../assets/images/loader-img/lamp-3.png";
import loader4 from "../assets/images/loader-img/lamp-4.png";
import loader5 from "../assets/images/loader-img/lamp-5.png";
import loader6 from "../assets/images/loader-img/lamp-6.png";
import loader7 from "../assets/images/loader-img/lamp-7.png";
import loader8 from "../assets/images/loader-img/lamp-middel.png";

// Menus & Header Images
import employer from "../assets/images/employee.png";
import employersSearch from "../assets/images/employers-search.png";
import contaract from "../assets/images/contaract.png";
import associations from "../assets/images/associations.png";
import report from "../assets/images/report.png";
import security from "../assets/images/security.png";
import utility from "../assets/images/Utility.png";
import manageUser from "../assets/images/usermanage.png";
import logout from "../assets/images/logout.png";
import userIcon from "../assets/images/userIcon.png";

// Others
import calender from "../assets/images/calender.png";
import logo from "../assets/images/logo.png";
import logow from "../assets/images/logo-white.png";
import clientimg from "../assets/images/LDC-Logo.png";
import bars from "../assets/images/bars.png";
import arrow from "../assets/images/arrow.png";
import edit from "../assets/images/editIcon.png";
import deleted from "../assets/images/deleteIcon.png";
import close from "../assets/images/close-icon.png";
import cross from "../assets/images/cross.png";
import copy from "../assets/images/copy.png";
import permission from "../assets/images/permission.png";
import user from "../assets/images/user.png";
import folder from "../assets/images/folder.png";
import subFolder from "../assets/images/sub-folder.png";
import subFolder1 from "../assets/images/sub-folder1.png";
import noRecord from "../assets/images/noRecord.png";
import welcome from "../assets/images/welcomeimg.png";
import search from "../assets/images/search.png";
import add from "../assets/images/addIcon.png";
import shortingArrow from "../assets/images/shortingArrow.png";
import unauthorized from "../assets/images/unauthorized.png"
import resetPassword from "../assets/images/reset-Password.png"


export const AppIcons = {
  // Loader
  loaderIcon1: loader1,
  loaderIcon2: loader2,
  loaderIcon3: loader3,
  loaderIcon4: loader4,
  loaderIcon5: loader5,
  loaderIcon6: loader6,
  loaderIcon7: loader7,
  loaderIcon8: loader8,

  // Menu
  employerMenu: employer,
  employerSearchMenu: employersSearch,
  contaractMenu: contaract,
  associationsMenu: associations,
  reportMenu: report,
  securityMenu: security,
  utilityMenu: utility,
  logoutMenu: logout,
  barsIcon: bars,
  userImage: userIcon,
  userManage: manageUser,

  // Others
  calenderIcon: calender,
  logoImage: logo,
  logoImageW: logow,
  clientLogo: clientimg,
  arrowIcon: arrow,
  editIcon: edit,
  deleteIcon: deleted,
  closeIcon: close,
  crossIcon: cross,
  copyIcon: copy,
  permissionIcon: permission,
  userIcon: user,
  folderIcon: folder,
  subFolderIcon: subFolder,
  subFolder1Icon: subFolder1,
  noRecordImg: noRecord,
  welComeImg: welcome,
  searchIcon:search,
  addIcon:add,
  shortingArrowIcon:shortingArrow,
  unauthorizedImg:unauthorized,
  resetPasswordImg:resetPassword,
};
