import React from "react";

import { securityKey } from "../../data/SecurityKey";

const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));
const TestTableGrid1 = React.lazy(() => import("../../pages/tableGrid/TestTableGrid1"));
const Dashboard = React.lazy(() => import('../../pages/dashboard/Dashboard'));

// Employer
const AddEditEmployer = React.lazy(() => import("../../pages/employers/employer/AddEditEmployer"));
const ViewAllEmployer = React.lazy(() => import("../../pages/employers/employer/ViewAllEmployers"));
// const EmployersSearch = React.lazy(() => import("../../pages/employers/employersSearch/EmployersSearch"));
const Contracts = React.lazy(() => import("../../pages/contracts/Contracts"));
const Associations = React.lazy(() => import("../../pages/associations/Associations"));
const AddEditAssociations = React.lazy(() => import("../../pages/associations/AddEditAssociations"));
const AddEditContracts = React.lazy(() => import("../../pages/contracts/AddEditContracts"));

// Reports
const EmployerReports = React.lazy(() => import("../../pages/reports/employerList/EmployerList"));
const ContractByCraft = React.lazy(() => import("../../pages/reports/contractByCraft/ContractByCraft"));
const EmployerDemographic = React.lazy(() => import("../../pages/reports/employerDemographic/EmployerDemographic"));
const ContractsByAssociation = React.lazy(() => import("../../pages/reports/contractsByAssociation/ContractsByAssociation"));
const UnsignedEmployers = React.lazy(() => import("../../pages/reports/unsignedEmployers/UnsignedEmployers"));
const BondReport = React.lazy(() => import("../../pages/reports/bondReport/BondReport"));
const ContractorBySignDate = React.lazy(() => import("../../pages/reports/contractorBySignDate/ContractorBySignDate"));

// Security
const ChangePassword = React.lazy(() => import("../../pages/security/changePassword/ChangePassword"));
const SecurityGroupManagement = React.lazy(() => import("../../pages/security/groupManagement/SecurityGroupManagement"));
const AddEditUser = React.lazy(() => import("../../pages/security/userManagement/AddEditUser"));
const ViewAllUser = React.lazy(() => import("../../pages/security/userManagement/ViewAllUser"));
const Permissions = React.lazy(() => import("../../pages/security/permission/Permission"));

// Utility
const Crafts = React.lazy(() => import("../../pages/utility/craft/Crafts"));
const Locals = React.lazy(() => import("../../pages/utility/locals/Locals"));
const ContractorWorkTypes = React.lazy(() => import("../../pages/utility/contractorWorkTypes/ContractorWorkTypes"));
const EmployerTypes = React.lazy(() => import("../../pages/utility/employerTypes/EmployerTypes"));
const ContractTypes = React.lazy(() => import("../../pages/utility/contractTypes/ContractTypes"));
const EmployerMerge = React.lazy(() => import("../../pages/utility/employerMerge/EmployerMerge"));

export const ComponentNavigation = [
  {
    id: "pageNotFound",
    path: "pageNotFound",
    exact: true,
    title: "",
    component: NotFound,
    text: "Page not found",
    securityKey: ""
  },
  {
    id: 'dashboard',
    path: '/Dashboard',
    exact: true,
    title: 'Dashboard',
    component: Dashboard,
    hasParams: false,
    text: 'Dashboard',
    securityKey: ""
  },
  // Employers
  {
    id: "employer",
    path: "Employer",
    exact: true,
    title: "Employer Page",
    component: ViewAllEmployer,
    hasParams: false,
    text: "Employer",
    securityKey: securityKey.EMPLOYERPAGE
  },
  {
    id: "addEmployer",
    path: "AddEmployer",
    exact: true,
    title: "Add Employer",
    component: AddEditEmployer,
    hasParams: false,
    text: "Add Employer",
    securityKey: securityKey.EMPLOYERPAGE
  },
  {
    id: "editEmployer",
    path: "EditEmployer/:id",
    exact: true,
    title: "Edit Employer",
    component: AddEditEmployer,
    hasParams: true,
    text: "EditEmployer",
    securityKey: securityKey.EMPLOYERPAGE
  },
  // {
  //   id: "employersSearch",
  //   path: "EmployersSearch",
  //   exact: true,
  //   title: "Employers Search",
  //   component: EmployersSearch,
  //   hasParams: false,
  //   text: "EmployersSearch",
  //   securityKey: securityKey.EMPLOYERSSEARCH
  // },
  // Contracts
  {
    id: "contracts",
    path: "Contracts",
    exact: true,
    title: "Contracts",
    component: Contracts,
    hasParams: false,
    text: "Contracts",
    securityKey: securityKey.CONTRACTS
  },
  {
    id: "addContracts",
    path: "AddContracts",
    exact: true,
    title: "Add Contracts",
    component: AddEditContracts,
    hasParams: false,
    text: "Add Contracts",
    securityKey: securityKey.CONTRACTS
  },
  {
    id: "editContracts",
    path: "EditContracts/:id",
    exact: true,
    title: "Edit Contracts",
    component: AddEditContracts,
    hasParams: true,
    text: "Edit Contracts",
    securityKey: securityKey.CONTRACTS
  },
  {
    id: "associations",
    path: "Associations",
    exact: true,
    title: "Associations",
    component: Associations,
    hasParams: false,
    text: "Associations",
    securityKey: securityKey.ASSOCIATIONS
  },
  {
    id: "addAssociations",
    path: "AddAssociations",
    exact: true,
    title: "Add Associations",
    component: AddEditAssociations,
    hasParams: false,
    text: "Associations",
    securityKey: securityKey.ASSOCIATIONS
  },
  {
    id: "editAssociations",
    path: "EditAssociations/:id",
    exact: true,
    title: "Edit Associations",
    component: AddEditAssociations,
    hasParams: true,
    text: "Associations",
    securityKey: securityKey.ASSOCIATIONS
  },
  // Reports
  {
    id: "employerList",
    path: "EmployerList",
    exact: true,
    title: "Employer List",
    component: EmployerReports,
    hasParams: false,
    text: "EmployerList",
    securityKey: securityKey.EMPLOYERLIST
  },
  {
    id: "contractByCraft",
    path: "ContractByCraft",
    exact: true,
    title: "Contract By Craft Report",
    component: ContractByCraft,
    hasParams: false,
    text: "ContractByCraft",
    securityKey: securityKey.CONTRACTBYCRAFTREPORT
  },
  {
    id: "employerDemographic",
    path: "EmployerDemographic",
    exact: true,
    title: "Employer Demographic Report",
    component: EmployerDemographic,
    hasParams: false,
    text: "EmployerDemographic",
    securityKey: securityKey.EMPLOYERDEMOGRAPHICREPORT
  },
  {
    id: "contractsByAssociation",
    path: "ContractsByAssociation",
    exact: true,
    title: "Contracts By Association Report",
    component: ContractsByAssociation,
    hasParams: false,
    text: "ContractsByAssociation",
    securityKey: securityKey.CONTRACTSBYASSOCIATIONREPORT
  },
  {
    id: "unsignedEmployers",
    path: "UnsignedEmployers",
    exact: true,
    title: "Unsigned Employers",
    component: UnsignedEmployers,
    hasParams: false,
    text: "UnsignedEmployers",
    securityKey: securityKey.UNSIGNEDEMPLOYERS
  },
  {
    id: "bondReport",
    path: "BondReport",
    exact: true,
    title: "Bond Report",
    component: BondReport,
    hasParams: false,
    text: "BondReport",
    securityKey: securityKey.BONDREPORT
  },
  {
    id: "contractorBySignDate",
    path: "ContractorBySignDate",
    exact: true,
    title: "Contractor By Sign Date",
    component: ContractorBySignDate,
    hasParams: false,
    text: "ContractorBySignDate",
    securityKey: securityKey.CONTRACTORBYSIGNDATE
  },
  // Security
  {
    id: "changePassword",
    path: "ChangePassword",
    exact: true,
    title: "Change Password",
    component: ChangePassword,
    hasParams: false,
    text: "ChangePassword",
    securityKey: ""
  },
  {
    id: "securityGroupManagement",
    path: "SecurityGroupManagement",
    exact: true,
    title: "Groups",
    component: SecurityGroupManagement,
    hasParams: false,
    text: "SecurityGroupManagement",
    securityKey: securityKey.SECURITYGROUPMANAGEMENT
  },
  {
    id: "addUser",
    path: "AddUser",
    exact: true,
    title: "User Information",
    component: AddEditUser,
    hasParams: false,
    text: "Add User",
    securityKey: securityKey.USERMANAGEMENT
  },
  {
    id: "editUser",
    path: "EditUser/:id",
    exact: true,
    title: "User Information",
    component: AddEditUser,
    hasParams: true,
    text: "Edit User",
    securityKey: securityKey.USERMANAGEMENT
  },
  {
    id: "users",
    path: "Users",
    exact: true,
    title: "User",
    component: ViewAllUser,
    hasParams: false,
    text: "Users",
    securityKey: securityKey.USERMANAGEMENT
  },
  {
    id: "permissions",
    path: "Permissions",
    exact: true,
    title: "Permissions",
    component: Permissions,
    hasParams: false,
    text: "Permissions",
    securityKey: securityKey.PERMISSIONS
  },
  {
    id: "editPermissions",
    path: "EditPermissions/:id",
    exact: true,
    title: "Permissions",
    component: Permissions,
    hasParams: true,
    text: "Permissions",
    securityKey: securityKey.PERMISSIONS
  },
  // Utility
  {
    id: "crafts",
    path: "Crafts",
    exact: true,
    title: "Crafts",
    component: Crafts,
    hasParams: false,
    text: "Crafts",
    securityKey: securityKey.CRAFTS
  },
  {
    id: "locals",
    path: "Locals",
    exact: true,
    title: "Locals",
    component: Locals,
    hasParams: false,
    text: "Locals",
    securityKey: securityKey.LOCALS
  },
  {
    id: "contractorWorkTypes",
    path: "ContractorWorkTypes",
    exact: true,
    title: "Contractor Work Types",
    component: ContractorWorkTypes,
    hasParams: false,
    text: "ContractorWorkTypes",
    securityKey: securityKey.CONTRACTORWORKTYPES
  },
  {
    id: "employerTypes",
    path: "EmployerTypes",
    exact: true,
    title: "Employer Types",
    component: EmployerTypes,
    hasParams: false,
    text: "EmployerTypes",
    securityKey: securityKey.EMPLOYERTYPES
  },
  {
    id: "contractTypes",
    path: "ContractTypes",
    exact: true,
    title: "Contract Types",
    component: ContractTypes,
    hasParams: false,
    text: "ContractTypes",
    securityKey: securityKey.CONTRACTTYPES
  },
  {
    id: "employerMerge",
    path: "EmployerMerge",
    exact: true,
    title: "Employer Merge",
    component: EmployerMerge,
    hasParams: false,
    text: "EmployerMerge",
    securityKey: securityKey.EMPLOYERMERGE
  },
  {
    id: "testTableGrid1",
    path: "TestTableGrid1",
    exact: true,
    title: "testTableGrid1",
    component: TestTableGrid1,
    hasParams: false,
    text: "TestTableGrid1",
    securityKey: ""
  }
];
