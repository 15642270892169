import { createApi } from '@reduxjs/toolkit/query/react';
import { transformErrorResponse, transformSucessResponse } from '../../utils/API/responseMiddleware';
import { customFetchBase } from '../../utils/API/fetchBaseQuery';
import { transformRequest, encryptQueryString } from '../../utils/API/requestMiddleware';

const workTypeAPI = createApi({
    reducerPath: 'workTypeAPI',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({

        // Get Work Types
        getWorkTypes: builder.mutation({
            query: (details) => ({
                url: '/WorkType/GetWorkTypes',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Add Work Types
        addWorkType: builder.mutation({
            query: (details) => ({
                url: '/WorkType/AddWorkType',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Update Work Types
        updateWorkType: builder.mutation({
            query: (details) => ({
                url: '/WorkType/UpdateWorkType',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        // Delete WorkType
        deleteWorkType: builder.mutation({
            query: (workTypeID) => ({
                url: encryptQueryString(`/WorkType/DeleteWorkType/?id=${workTypeID}`),
                method: 'DELETE',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllWorkType: builder.query({
            query: () => ({
                url: '/WorkType/GetAllWorkType',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        })
    })
})

export const { useGetWorkTypesMutation, useAddWorkTypeMutation, useUpdateWorkTypeMutation, useDeleteWorkTypeMutation, useLazyGetAllWorkTypeQuery } = workTypeAPI;

export default workTypeAPI;