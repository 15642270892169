import { FormFieldTypes } from "../../data/formFieldType";

const ResetPasswordData = {
    name: "Login From",
    initialState: { userName: "", newPassword: "", confirmPassword: "" },
    formFields: [
        {
            id: "userName",
            lable: "User Name",
            Field_Name: "Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "userName",
            fieldSetting: {
                placeholder: "User Name",
                allowSpace: false,
                isDisable: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12"
            }
        },
        {
            id: "NewPassword",
            lable: "New Password",
            Field_Name: "NewPassword",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "newPassword",
            fieldSetting: {
                placeholder: "Password",
            },
            style: {
                containerCss: "col-md-12"
            },
            validation: [{ type: "require" }, { type: "password" }]
        },
        {
            id: "confirmPassword",
            lable: "Confirm Password",
            Field_Name: "Confirm Password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "confirmPassword",
            fieldSetting: {
                placeholder: "Confirm Your Password",
            },
            validation: [{ type: "require" }, { type: "compare", compareEle: "newPassword" }],
            style: {
                containerCss: "col-md-12 mb-3",
            },
        },
    ],
};

export default ResetPasswordData;