import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLoader from "../../components/ui/pageLoader/PageLoader";
import ProtectedRoute from "../Routes/ProtectedRoute";
import Layout from "../../layouts/Layout";
import ResetPassword from "../../pages/resetPassword/ResetPassword";


const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));
const Login = React.lazy(() => import("../../pages/login/Login"));

const AppRoutes = (props) => {
  return (
    <Router>
      <React.Suspense fallback={<PageLoader />}>
        <Routes>
          <Route name="Layout" path="/" element={
            <ProtectedRoute >
              <Layout componentRoutes={props.componentRoutes} >

              </Layout>
            </ProtectedRoute>
          }
          >
            {
              props.componentRoutes.map((route, idx) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      name={"test"}
                      element={<route.component securityKey={route.securityKey}/>}
                    />
              ))
            }

          </Route>
          <Route exact name="Login" path="/login" element={<Login />} />
          <Route exact name="ResetPassword" path="/ResetPassword" element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </Router>

  );
};
export default AppRoutes;
